var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.users}},[_c('el-table-column',{attrs:{"label":"Name","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":{
          name: 'ProfileForAdmin',
          params: { id: scope.row.id }
        }}},[_vm._v(" "+_vm._s(scope.row.name)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"id","label":"uid","width":"120"}}),_c('el-table-column',{attrs:{"prop":"username","label":"Username","width":"160"}}),_c('el-table-column',{attrs:{"prop":"email","label":"Email"}}),_c('el-table-column',{attrs:{"prop":"phone","label":"Phone","width":"140"}}),_c('el-table-column',{attrs:{"prop":"lineId","label":"Line","width":"140"}}),_c('el-table-column',{attrs:{"prop":"wechat","label":"Wechat","width":"140"}}),_c('el-table-column',{attrs:{"label":"Action","align":"center","width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tooltip',{staticClass:"item",attrs:{"content":"User Profile","placement":"top","popper-class":"tooltipColor"}},[_c('router-link',{attrs:{"to":{
            name: 'ProfileForAdmin',
            params: { id: scope.row.id }
          }}},[_c('i',{staticClass:"fas fa-eye action-icon"})])],1),(_vm.$store.getters['user/isRole']('super-admin'))?_c('el-tooltip',{staticClass:"item",attrs:{"content":`Click to mark ${scope.row.isActive ? 'inactive' : 'active'}`,"placement":"top","popper-class":"tooltipColor"}},[(scope.row.isActive)?_c('span',{key:"inactive",on:{"click":function($event){return _vm.$emit('toggleUserActive', scope.row.id)}}},[_c('i',{staticClass:"far fa-stop-circle action-icon"})]):_c('span',{key:"active",on:{"click":function($event){return _vm.$emit('toggleUserActive', scope.row.id)}}},[_c('i',{staticClass:"far fa-play-circle action-icon"})])]):_vm._e(),(_vm.$store.getters['user/isRole']('super-admin'))?_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":() => {
            _vm.$emit('deleteUser', scope.row.id);
          }}},[_c('i',{staticClass:"el-icon-delete warning-icon"})]):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }